import { Injectable } from "@angular/core";
import { IRomulusPostMessage, NotificationTypes, RomulusMessageTypes } from "../../models/integrations/romulus.model";
import { Logger, LoggerService } from "../../../core/services/logger";
import { BehaviorSubject } from "rxjs";
import { AUDIO_TYPE, AudioService, ConfigService } from "../../../core/services";
import { Router } from "@angular/router";
import { NotificationService } from "../../../core/services/notifications";

@Injectable({
  providedIn: 'root'
})
export class RomulusInboxService {

  private log: Logger;
  private _iframe: HTMLIFrameElement = document.createElement('iframe');
  
  private notifications: IRomulusPostMessage[] = [];
  private _notificationsCount: number = 0;

  public notifications$: BehaviorSubject<IRomulusPostMessage[]> = new BehaviorSubject(this.notifications);
  public notificationsCount$: BehaviorSubject<number> = new BehaviorSubject(this._notificationsCount);

  constructor(
    private loggerService: LoggerService,
    private configService: ConfigService,
    private audioService: AudioService,
    private router: Router,
    private notificationService: NotificationService
  ){
    this.log = loggerService.getLoggerInstance('RomulusInbox');
  }

  public init(token: string) {
    this._iframe.src = `https://chat.romulus.live/api-login?token=${token}`;
    this._iframe.frameBorder = '0';
    this._iframe.style.position = 'absolute';
    this._iframe.hidden = true;
    document.body.appendChild(this._iframe);
    this._iframe.addEventListener('error', (err: ErrorEvent) => {
      this.notificationService.error('inbox.frame-error');
    });
    this.attachPostMessage();
  }

  public get iframe(): HTMLIFrameElement {
    return this._iframe;
  }

  public get notificationsCount(): number {
    return this._notificationsCount;
  }

  public removeNotificationAt(index: number) {
    this.notifications.splice(index, 1);
    this.notifications$.next(this.notifications);
  }

  private attachPostMessage(){
    window.addEventListener('message', (message) => {
      if(message.origin === 'https://chat.romulus.live' && message.data)
        this.processPostMessage(message.data);
    });
  }

  private processPostMessage(message: IRomulusPostMessage): void {
    if( !this.configService.config.settings.doNotDisturb ){
      this.addNotification(message);
      this.audioService.playAndStop(AUDIO_TYPE.MESSAGE_RECEIVED);
    }
    if(this.router.url !== '/home/inbox'){
      this.addNotificationCount();
    }
  }

  private addNotification(message: IRomulusPostMessage) {
    this.notifications.push(message);
    this.notifications$.next(this.notifications);
  }

  private clearAllNotifications(): void {
    this.notifications = [];
    this.notifications$.next(this.notifications);
  }

  private addNotificationCount(): void {
    this._notificationsCount++;
    this.notificationsCount$.next(this._notificationsCount);
  }

  public clearNotificationCount(): void {
    this._notificationsCount = 0;
    this.notificationsCount$.next(this._notificationsCount);
    this.clearAllNotifications();
  }
}