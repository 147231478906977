import { Component, OnDestroy, OnInit } from '@angular/core';
import { RomulusInboxService } from '../../services/romulus/romulus.service';
import { IRomulusPostMessage, RomulusMessageTypes } from '../../models/integrations/romulus.model';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'romulus-chat-notification',
  templateUrl: './romulus-chat-notification.component.html',
  styleUrls: ['./romulus-chat-notification.component.scss']
})
export class RomulusChatNotificationComponent implements OnInit, OnDestroy {

  private activeNotificationsSubscription: Subscription;
  public activeNotifications: IRomulusPostMessage[] = [];
  public notificationTypes = RomulusMessageTypes;

  public autoHideDelay: number = 10 * 1000; //seconds

  constructor(
    private romulusService: RomulusInboxService,
    private router: Router
  ){}

  ngOnInit(): void {
    this.activeNotificationsSubscription = this.romulusService.notifications$.subscribe((value: IRomulusPostMessage[]) => {
      this.activeNotifications = value;
    });
  }

  ngOnDestroy(): void {
    this.activeNotificationsSubscription?.unsubscribe();
  }

  public openChat(index: number): void {
    this.dismiss(index);
    this.router.navigate(['home', 'inbox']);
  }

  public dismiss(index: number, evt: InputEvent = null): void {
    evt?.stopImmediatePropagation();
    evt?.stopPropagation();
    this.romulusService.removeNotificationAt(index);
  }

  public onHide(index: number) {
    this.dismiss(index);
  }
}
